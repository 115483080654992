import {Attachment, ProtocolEntryPriorityType} from 'submodules/baumaster-v2-common';
import {ScaleImageFixedOption} from '../model/attachments';

export enum StorageKeyEnum {
  REPORTS_FILTER = 'reportsFilter',
  LAST_USED_PROJECT = 'lastUsedProject',
  DARK_THEME = 'darkTheme',
  SYNC_HISTORY = 'syncHistory',
  SYNC_TO_SERVER_HISTORY = 'syncToServerHistory',
  SYNC_SCHEDULER = 'syncScheduler',
  SELECTED_LANGUAGE = 'selectedLanguage',
  FILE_ACCESS_UTIL = 'fileAccessUtil',
  AUTHENTICATION = 'authentication',
  ANALYTICS_DISABLED = 'analyticsDisabled',
  ANALYTICS_USER_INFO_ENABLED = 'analyticsUserInfoEnabled',
  PROJECT_META_INFO = 'projectMetaInfo',
  SYSTEM_EVENT = 'systemEvent',
  LAST_USED_PROTOCOL = 'lastUsedProtocol',
  DASHBOARD_SETTINGS = 'dashboardSettings',
  PROTOCOL_SORT_ORDER = 'protocolSortOrder',
  ADDRESS = 'address',
  COUNTRY = 'country',
  ATTACHMENT_CHAT = 'attachmentChat',
  ATTACHMENT_PROTOCOL_ENTRY = 'attachmentProtocolEntry',
  ATTACHMENT_PROJECT = 'attachmentProject',
  ATTACHMENT_PROJECT_BANNER = 'attachmentProjectBanner',
  ATTACHMENT_PROJECT_IMAGE = 'attachmentProjectImage',
  CONSTRUCTION_SCHEDULE_SETTINGS = 'constructionScheduleSettings',
  /**
   * @deprecated tdom: Seems like it's deprecated/unused
   */
  ATTACHMENT_PDFPLANPAGE = 'attachmentPdfPlanPage',
  ATTACHMENT_CLIENT = 'attachmentClient',
  PROFILE_CRAFT = 'profileCraft',
  COMPANY_CRAFT = 'companyCraft',
  COMPANY = 'company',
  CRAFT = 'craft',
  PDF_PLAN_MARKER_PROTOCOL_ENTRY = 'pdfPlanMarkerProtocolEntry',
  PDF_PLAN_ATTACHMENT = 'pdfPlanAttachment',
  PDF_PLAN_FOLDER = 'pdfPlanFolder',
  PDF_PLAN_PAGE = 'pdfPlanPage',
  PDF_PLAN = 'pdfPlan',
  PDF_PLAN_VERSION = 'pdfPlanVersion',
  PROFILE = 'profile',
  PROJECT_COMPANY = 'projectCompany',
  CURRENT_PROJECT = 'currentProject',
  PROJECT = 'project',
  PROTOCOL = 'protocol',
  PROTOCOL_ENTRY_CHAT = 'protocolEntryChat',
  PROTOCOL_ENTRY = 'protocolEntry',
  PROTOCOL_ENTRY_LOCATION = 'protocolEntryLocation',
  PROTOCOL_ENTRY_TYPE = 'protocolEntryType',
  PROTOCOL_LAYOUT = 'protocolLayout',
  PROTOCOL_TYPE = 'protocolType',
  USER = 'user',
  USER_EMAIL_SIGNATURE = 'userEmailSignature',
  USER_OFFLINE_PROJECT = 'userOfflineProject',
  USER_PUBLIC = 'userPublic',
  USER_INVITE = 'userInvite',
  USER_CONNECTION_INVITE = 'userConnectionInvite',
  PROJECT_CRAFT = 'projectCraft',
  PROJECT_PROFILE = 'projectProfile',
  PROJECT_PROTOCOL_ENTRY_TYPE = 'projectProtocolEntryType',
  PROJECT_PROTOCOL_LOCATION = 'projectProtocolLocation',
  PROJECT_PROTOCOL_TYPE = 'projectProtocolType',
  CLIENT = 'client',
  NAMEABLE_DROPDOWN = 'nameableDropdown',
  NAMEABLE_DROPDOWN_ITEM = 'nameableDropdownItem',
  PROTOCOL_OPEN_ENTRY = 'protocolOpenEntry',
  /**
   * @deprecated tdom: Seems like it's deprecated/unused
   */
  PROJECT_PROFILE_CRAFT = 'projectProfileCraft',
  PARTICIPANT = 'participant',
  CUSTOM_PDF_CONFIGURATION = 'customPdfConfiguration',
  PDF_PROTOCOL_SETTING = 'pdfProtocolSetting',
  PDF_PREVIEW = 'pdfPreview',
  CONSTRUCTION_SCHEDULE_TASK = 'constructionScheduleTask',
  CONSTRUCTION_SCHEDULE_LINK = 'constructionScheduleLink',
  REPORT = 'report',
  REPORT_ACTIVITY = 'reportActivity',
  REPORT_TYPE = 'reportTypes',
  CUSTOM_REPORT_TYPE = 'customReportTypes',
  PROJECT_CUSTOM_REPORT_TYPE = 'projectCustomReportTypes',
  REPORT_EMPLOYEE = 'reportEmployee',
  REPORT_MACHINE = 'reportMachine',
  REPORT_MATERIAL = 'reportMaterial',
  REPORT_SUPPLIER = 'reportSupplier',
  REPORT_WEEK = 'reportWeek',
  ATTACHMENT_REPORT_ACTIVITY = 'attachmentReportActivity',
  ATTACHMENT_REPORT_EQUIPMENT = 'attachmentReportEquipment',
  ATTACHMENT_REPORT_MATERIAL = 'attachmentReportMaterial',
  ATTACHMENT_REPORT_COMPANY = 'attachmentReportCompany',
  ATTACHMENT_REPORT_SIGNATURE = 'attachmentReportSignature',
  ATTACHMENT_PROTOCOL_SIGNATURE = 'attachmentProtocolSignature',
  ATTACHMENT_USER_EMAIL_SIGNATURE = 'attachmentUserEmailSignature',
  ACTIVITY = 'activity',
  EQUIPMENT = 'equipment',
  MATERIAL = 'material',
  REPORT_COMPANY = 'reportCompany',
  REPORT_COMPANY_ACTIVITY = 'reportCompanyActivity',
  STAFFING_TYPE = 'staffingType',
  STAFF = 'staff',
  ADDITIONAL_PAY_TYPE = 'additionalPayType',
  EMPLOYEE = 'employee',
  PROTOCOL_ENTRY_COMPANY = 'protocolEntryCompany',
  PDF_PLAN_PAGE_MARKING = 'pdfPlanPageMarking',
  TAG_CLIENT = 'tagClient',
  TAG_CLIENT_OBJECT = 'tagClientObject',
  SHARE_VERSIONS_EMAIL_SETTINGS_TEMPLATE = 'shareVersionsEmailSettingsTemplate',
  PDF_PLAN_VERSION_ACCESS = 'pdfPlanVersionAccess',
  PDF_PLAN_VERSION_DISTRIBUTION_ACCESS = 'pdfPlanVersionDistributionAccess',
  APP_TOKEN = 'appToken',
  APP_TOKEN_PERMISSION = 'appTokenPermission',
  SELECTABLE_RECENTLY_USED = 'selectableRecentlyUsed',
  ENTRY_CARD_SETTINGS = 'entryCardSettings',
  CALENDAR = 'calendar',
  CLIENT_CALENDAR = 'clientCalendar',
  PROJECT_CALENDAR = 'projectCalendar',
  CALENDAR_DAY = 'calendarDay',
  CLIENT_CALENDAR_DAY = 'clientCalendarDay',
  PROJECT_CALENDAR_DAY = 'projectCalendarDay',
  PROJECT_CLIENT_CALENDAR = 'projectClientCalendar',
  TEXT_TEMPLATE = 'textTemplate',
  CLIENT_TEXT_TEMPLATE = 'clientTextTemplate',
  ENTRY_CREATION_MODE = 'entryCreationMode',
  PICTURE_QUALITY = 'pictureQuality',
  UPDATE_REMIND_DATE = 'updateRemindDate',
  BIM_MARKER = 'bimMarker',
  ATTACHMENT_BIM_MARKER_SCREENSHOT = 'attachmentBimMarkerScreenshot',
  BIM_PLAN = 'bimPlan',
  BIM_VERSION = 'bimVersion',
  BIM_VERSION_ACCESS = 'bimVersionAccess',
  BIM_ENTRY_CARD_SETTINGS = 'bimEntryCardSettings',
  PROTOCOL_ENTRY_CARD_SETTINGS = 'protocolEntryCardSettings',
  POSTHOG_FEATURE_OPTED_IN = 'posthogFeatureOptedIn',
  USER_DEVICE_OFFLINE_PROJECT = 'userDeviceOfflineProject',
  PROJECT_AVAILABILITY_EXPIRATION = 'projectAvailabilityExpiration',
  PROJECT_LOADING_NOTIFICATION = 'projectLoadingNotification',
  NOTIFICATION_EVENT = 'notificationEvent',
  NOTIFICATION_EVENT_RECIPIENT = 'notificationEventRecipient',
  NOTIFICATION_CONFIG = 'notificationConfig',
  NOTIFICATION_CONFIG_RECIPIENT = 'notificationConfigRecipient',
  NOTIFICATION_ALERT = 'notificationAlert',
  SIDEBAR_SETTING = 'sideBarSetting',
  SYNC_PERFORMANCE_STATISTIC = 'syncPerformanceStatistic',
}

export type DeviceOnlyStorageKeys = StorageKeyEnum.REPORTS_FILTER
| StorageKeyEnum.LAST_USED_PROJECT
| StorageKeyEnum.DARK_THEME
| StorageKeyEnum.SYNC_HISTORY
| StorageKeyEnum.SYNC_TO_SERVER_HISTORY
| StorageKeyEnum.SYNC_SCHEDULER
| StorageKeyEnum.SELECTED_LANGUAGE
| StorageKeyEnum.FILE_ACCESS_UTIL
| StorageKeyEnum.AUTHENTICATION
| StorageKeyEnum.ANALYTICS_DISABLED
| StorageKeyEnum.ANALYTICS_USER_INFO_ENABLED
| StorageKeyEnum.PROJECT_META_INFO
| StorageKeyEnum.SYSTEM_EVENT
| StorageKeyEnum.LAST_USED_PROTOCOL
| StorageKeyEnum.DASHBOARD_SETTINGS
| StorageKeyEnum.PROTOCOL_SORT_ORDER
| StorageKeyEnum.ATTACHMENT_PDFPLANPAGE
| StorageKeyEnum.PROJECT_PROFILE_CRAFT
| StorageKeyEnum.CURRENT_PROJECT
| StorageKeyEnum.CONSTRUCTION_SCHEDULE_SETTINGS
| StorageKeyEnum.SELECTABLE_RECENTLY_USED
| StorageKeyEnum.ENTRY_CARD_SETTINGS
| StorageKeyEnum.ENTRY_CREATION_MODE
| StorageKeyEnum.PICTURE_QUALITY
| StorageKeyEnum.UPDATE_REMIND_DATE
| StorageKeyEnum.BIM_ENTRY_CARD_SETTINGS
| StorageKeyEnum.PROTOCOL_ENTRY_CARD_SETTINGS
| StorageKeyEnum.POSTHOG_FEATURE_OPTED_IN
| StorageKeyEnum.PROJECT_AVAILABILITY_EXPIRATION
| StorageKeyEnum.PROJECT_LOADING_NOTIFICATION
| StorageKeyEnum.NOTIFICATION_ALERT
| StorageKeyEnum.SIDEBAR_SETTING
| StorageKeyEnum.SYNC_PERFORMANCE_STATISTIC;

export type StorageKeyOnServerEnum = Exclude<StorageKeyEnum, DeviceOnlyStorageKeys>;

export enum FirebaseEventEnum {
  APPLICATION_INFO = 'application_info',
  APPLICATION_ERROR = 'application_error'
}

export const ToastDurationInMs = {
  INFO: 2000,
  INFO_WITH_MESSAGE: 3000,
  ERROR: 5000
};

export const INIT_AFTER_APP_START_DELAY_IN_MS = 1000;
export const AUTOSAVE_DELAY_IN_MS = 5000;

export const PROTOCOL_LAYOUT_NAME_CONTINUOUS = 'Fortlaufend';
export const PROTOCOL_LAYOUT_NAME_SHORT = 'Kurz';
export const PROTOCOL_LAYOUT_NAME_STANDARD = 'Standard';

export const PROTOCOL_TYPE_RECOVER_NAME = 'Recover';
export const PROTOCOL_TYPE_RECOVER_CODE = 'Recove';

export const LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY = 30;
export const LIMIT_ATTACHMENTS_NUMBER_COMMENT = 10;
export const LIMIT_ATTACHMENTS_NUMBER_REPORT_ACTIVITY = 10;

export const LIMIT_PROTOCOL_ENTRY_FILTER_CHIPS = 3;

export const WIDTH_LIMIT_SPLIT_SCREEN = 992;
export const IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES = 2097152;
export const SMALL_SCREEN_SIZE = 576;
export const MEDIUM_SCREEN_SIZE = 768;
export const LARGE_SCREEN_SIZE = 992;
export const XLARGE_SCREEN_SIZE = 1200;

export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_AND_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const MOMENT_DATE_FORMAT = 'DD.MM.yyyy';
export const MOMENT_DATE_FORMAT_FILE_NAME = 'DD-MM-yyyy';
export const DATE_FORMAT_WITH_SHORT_YEAR = 'dd.MM.yy';
export const WARNING_STORAGE_SPACE_LIMIT_IN_BYTES = 1024 * 1024 * 1024;
export const CRITICAL_STORAGE_SPACE_LIMIT_IN_BYTES = 500 * 1024 * 1024;
export const CACHE_STORAGE_SIZE_META_SIZE_FACTOR = 1.0102;
export const CONTACT_FORM_AUTOSAVE_DELAY_IN_MS = 5000;
export const PHONE_NUMBER_PATTERN = '([0-9]|[\\(\\)\\-_+#]|[ ])+';

export const IMAGE_SIZE_LARGE_SQUARE_HEIGHT = 90;
export const IMAGE_SIZE_LARGE_SQUARE_WIDTH = 90;

export const EMPTY_IMAGE_HEIGHT = 802;
export const EMPTY_IMAGE_WIDTH = 1070;
export const EMPTY_IMAGE_BACKGROUND = '#ffffff';
export const IMAGE_JPEG = 'image/jpeg';

export const PROTOCOL_COLORS = ['#FF0102', '#810002', '#BFBF00', '#818003', '#07B503', '#048004', '#2691C8', '#048080', '#1402FF',
  '#EF9600', '#FF6101', '#FF01FF', '#800080', '#A0A0A0', '#455A64', '#808080', '#000000'];
export const DEFAULT_PROTOCOL_COLOR = '#2691C8';

export const STORAGE_KEY_PROJECT_SEPARATOR = '_';
export const REPORT_TYPE_DIARY = 'REPORT_TYPE_CONSTRUCTION_DIARY';

export const SUPPORTED_LANGUAGES = new Array<string>('de', 'en', 'fr');

export const TAX_RATES = new Map<string, number>([
  ['österreich', 20],
  ['deutschland', 19],
  ['schweiz', 7.7],
  ['frankreich', 20],
]);

// Object created this way to ensure all StorageKeyOnServerEnum values are defined below.
// (Map entries does not throw compilation errors, where records do)
const isAwareObject: Record<StorageKeyOnServerEnum, 'CLIENT_AWARE' | 'PROJECT_AWARE' | 'NON_AWARE'> = {
  [StorageKeyEnum.CLIENT]: 'NON_AWARE',
  [StorageKeyEnum.USER]: 'NON_AWARE',
  [StorageKeyEnum.USER_EMAIL_SIGNATURE]: 'NON_AWARE',
  [StorageKeyEnum.ATTACHMENT_CLIENT]: 'NON_AWARE',
  [StorageKeyEnum.REPORT_TYPE]: 'NON_AWARE',
  [StorageKeyEnum.ADDRESS]: 'CLIENT_AWARE',
  [StorageKeyEnum.COMPANY]: 'CLIENT_AWARE',
  [StorageKeyEnum.COMPANY_CRAFT]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROFILE_CRAFT]: 'CLIENT_AWARE',
  [StorageKeyEnum.CRAFT]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROFILE]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROJECT]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROTOCOL_ENTRY_LOCATION]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROTOCOL_ENTRY_TYPE]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROTOCOL_LAYOUT]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROTOCOL_TYPE]: 'CLIENT_AWARE',
  [StorageKeyEnum.USER_PUBLIC]: 'CLIENT_AWARE',
  [StorageKeyEnum.USER_OFFLINE_PROJECT]: 'CLIENT_AWARE',
  [StorageKeyEnum.NAMEABLE_DROPDOWN]: 'CLIENT_AWARE',
  [StorageKeyEnum.CUSTOM_PDF_CONFIGURATION]: 'CLIENT_AWARE',
  [StorageKeyEnum.COUNTRY]: 'CLIENT_AWARE',
  [StorageKeyEnum.ATTACHMENT_PROJECT_IMAGE]: 'CLIENT_AWARE',
  [StorageKeyEnum.CUSTOM_REPORT_TYPE]: 'CLIENT_AWARE',
  [StorageKeyEnum.USER_INVITE]: 'CLIENT_AWARE',
  [StorageKeyEnum.USER_CONNECTION_INVITE]: 'CLIENT_AWARE',
  [StorageKeyEnum.APP_TOKEN]: 'CLIENT_AWARE',
  [StorageKeyEnum.APP_TOKEN_PERMISSION]: 'CLIENT_AWARE',

  [StorageKeyEnum.ATTACHMENT_CHAT]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_PROJECT]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_PROJECT_BANNER]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_PROTOCOL_ENTRY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_MARKER_PROTOCOL_ENTRY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_ATTACHMENT]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_PAGE]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_VERSION]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_FOLDER]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROTOCOL]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROTOCOL_ENTRY_CHAT]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROTOCOL_ENTRY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROTOCOL_OPEN_ENTRY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_COMPANY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_CRAFT]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_PROFILE]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_PROTOCOL_ENTRY_TYPE]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_PROTOCOL_LOCATION]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_PROTOCOL_TYPE]: 'PROJECT_AWARE',
  [StorageKeyEnum.NAMEABLE_DROPDOWN_ITEM]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PROTOCOL_SETTING]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PREVIEW]: 'PROJECT_AWARE',
  [StorageKeyEnum.CONSTRUCTION_SCHEDULE_TASK]: 'PROJECT_AWARE',
  [StorageKeyEnum.CONSTRUCTION_SCHEDULE_LINK]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_REPORT_ACTIVITY]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_REPORT_EQUIPMENT]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_REPORT_MATERIAL]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_REPORT_COMPANY]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_REPORT_SIGNATURE]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_PROTOCOL_SIGNATURE]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_USER_EMAIL_SIGNATURE]: 'NON_AWARE',
  [StorageKeyEnum.ACTIVITY]: 'PROJECT_AWARE',
  [StorageKeyEnum.EQUIPMENT]: 'PROJECT_AWARE',
  [StorageKeyEnum.MATERIAL]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_ACTIVITY]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_EMPLOYEE]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_MACHINE]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_MATERIAL]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_SUPPLIER]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_WEEK]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_CUSTOM_REPORT_TYPE]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_COMPANY]: 'PROJECT_AWARE',
  [StorageKeyEnum.REPORT_COMPANY_ACTIVITY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PARTICIPANT]: 'PROJECT_AWARE',
  [StorageKeyEnum.STAFFING_TYPE]: 'CLIENT_AWARE',
  [StorageKeyEnum.STAFF]: 'PROJECT_AWARE',
  [StorageKeyEnum.ADDITIONAL_PAY_TYPE]: 'CLIENT_AWARE',
  [StorageKeyEnum.EMPLOYEE]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROTOCOL_ENTRY_COMPANY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_PAGE_MARKING]: 'PROJECT_AWARE',
  [StorageKeyEnum.TAG_CLIENT]: 'CLIENT_AWARE',
  [StorageKeyEnum.TAG_CLIENT_OBJECT]: 'CLIENT_AWARE',
  [StorageKeyEnum.SHARE_VERSIONS_EMAIL_SETTINGS_TEMPLATE]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_VERSION_ACCESS]: 'PROJECT_AWARE',
  [StorageKeyEnum.PDF_PLAN_VERSION_DISTRIBUTION_ACCESS]: 'PROJECT_AWARE',
  [StorageKeyEnum.CALENDAR]: 'NON_AWARE',
  [StorageKeyEnum.CALENDAR_DAY]: 'NON_AWARE',
  [StorageKeyEnum.CLIENT_CALENDAR]: 'CLIENT_AWARE',
  [StorageKeyEnum.CLIENT_CALENDAR_DAY]: 'CLIENT_AWARE',
  [StorageKeyEnum.PROJECT_CALENDAR]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_CALENDAR_DAY]: 'PROJECT_AWARE',
  [StorageKeyEnum.PROJECT_CLIENT_CALENDAR]: 'PROJECT_AWARE',
  [StorageKeyEnum.TEXT_TEMPLATE]: 'NON_AWARE',
  [StorageKeyEnum.CLIENT_TEXT_TEMPLATE]: 'CLIENT_AWARE',
  [StorageKeyEnum.BIM_MARKER]: 'PROJECT_AWARE',
  [StorageKeyEnum.ATTACHMENT_BIM_MARKER_SCREENSHOT]: 'PROJECT_AWARE',
  [StorageKeyEnum.BIM_PLAN]: 'PROJECT_AWARE',
  [StorageKeyEnum.BIM_VERSION]: 'PROJECT_AWARE',
  [StorageKeyEnum.BIM_VERSION_ACCESS]: 'PROJECT_AWARE',
  [StorageKeyEnum.USER_DEVICE_OFFLINE_PROJECT]: 'CLIENT_AWARE',
  [StorageKeyEnum.NOTIFICATION_EVENT]: 'NON_AWARE',
  [StorageKeyEnum.NOTIFICATION_EVENT_RECIPIENT]: 'NON_AWARE',
  [StorageKeyEnum.NOTIFICATION_CONFIG]: 'NON_AWARE',
  [StorageKeyEnum.NOTIFICATION_CONFIG_RECIPIENT]: 'NON_AWARE',
};

export const IS_AWARE = new Map(
  Object.entries(isAwareObject) as [StorageKeyEnum, 'CLIENT_AWARE' | 'PROJECT_AWARE' | 'NON_AWARE'][]
);

export const GANTT_PDF_GENERATION_TIMEOUT_IN_MS = 60 * 1000;
export const EMAIL_REG_EXP_PATTERN = '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$';

export const ATTACHMENT_DEFAULT_SORT_COLUMNS: Array<keyof Attachment | ((item: Attachment) => any)> = ['createdAt', 'id'];
export const ATTACHMENT_DEFAULT_SORT_ORDER: Array<'asc'|'desc'> = ['asc', 'asc'];

export const PROJECT_NUMBER_MAX_LENGTH = 10;
export const REG_SPECIAL = /[^\w-\.]/g;
export const ALL_FOLDERS_PAGE_SLUG = 'all';

export const LOCAL_STORAGE_WINDOW_ID_STARTED = 'WindowIdStarted';
export const LOCAL_STORAGE_REQUEST_ACTIVE = 'RequestWindowIdActive';
export const LOCAL_STORAGE_ACTIVE_REPLY_PREFIX = 'WindowIdActive_';
export const LOCAL_STORAGE_STORAGE_DRIVER = 'StorageDriver';
export const LOCAL_STORAGE_STORAGE_DRIVER_VALUE_INDEXEDDB = 'IndexedDB';
export const LOCAL_STORAGE_STORAGE_DRIVER_VALUE_SQLITE = 'SQLite';
export const PDF_TO_IMAGE_CONVERSION_TIMEOUT_IN_MS = 180000;

export const DEFAULT_ENTRY_PRIORITY: ProtocolEntryPriorityType = 4;

export const CONSTRUCTION_SCHEDULE_TASK_NAME_MAX_LENGTH = 60;
export const CONSTRUCTION_SCHEDULE_TASK_MAX_YEARS_FROM_TO_NOW = 100;
export const CONSTRUCTION_SCHEDULE_TASK_MAX_YEARS_FROM_TO_NOW_IN_MS = CONSTRUCTION_SCHEDULE_TASK_MAX_YEARS_FROM_TO_NOW * 365 * 24 * 60 * 60 * 1000;
export const CONSTRUCTION_SCHEDULE_TASK_MAX_DURATION_IN_DAYS = 365 * 10;
export const CONSTRUCTION_SCHEDULE_TASK_MAX_DURATION_IN_MS = CONSTRUCTION_SCHEDULE_TASK_MAX_DURATION_IN_DAYS * 24 * 60 * 60 * 1000;

export const IMAGE_SCALE_FOR_CLIENT_LOGO: ScaleImageFixedOption = { fixedHeight: 550, fixedWidth: 347, imageConversion: 'stretch' };
export const IMAGE_SCALE_FOR_PROJECT_IMAGE: ScaleImageFixedOption = { fixedHeight: 550, fixedWidth: 660, imageConversion: 'stretch' };
export const IMAGE_SCALE_FOR_PDF_BANNER: ScaleImageFixedOption = { fixedHeight: 400, fixedWidth: 2003, imageConversion: 'stretch' };
export const TEMPERATURE_MIN_VALUE = -30;
export const TEMPERATURE_MAX_VALUE = 40;

const TEMPORARILY_PROJECT_EXPIRATION_IN_DAYS = 1;
const DOWNLOADED_PROJECT_EXPIRATION_IN_DAYS = 60;
const AVAILABLE_PROJECT_EXPIRATION_IN_DAYS = 10;
export const TEMPORARILY_PROJECT_EXPIRATION_IN_MS = TEMPORARILY_PROJECT_EXPIRATION_IN_DAYS * 24 * 60 * 60 * 1000;
export const DOWNLOADED_PROJECT_EXPIRATION_IN_MS = DOWNLOADED_PROJECT_EXPIRATION_IN_DAYS * 24 * 60 * 60 * 1000;
export const AVAILABLE_PROJECT_EXPIRATION_IN_MS = AVAILABLE_PROJECT_EXPIRATION_IN_DAYS * 24 * 60 * 60 * 1000;
export const ALLOW_INTERACTION_DEADLINE_IN_MS = 1000;
export const DASHBOARD_GLOBAL_SEARCH_ENSURE_DATA_NOT_OLDER_THAN_MS = 10 * 60 * 1000;

export const PDFJS_DIST_WORKER_PATH = '/pdfjs-2.14.305/pdf.worker.min.js';

export const PINNED_MENU_WIDTH = 270;