import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {
  Address,
  Client,
  Company,
  CompanyCraft,
  Craft,
  getFormattedNumber,
  IdAware,
  IdType,
  Profile,
  Project,
  ProjectCompany,
  ProjectProfile,
  Protocol,
  ProtocolEntry,
  ProtocolEntryLocation,
  ProtocolEntryPriorityLevel,
  ProtocolEntryType
} from 'submodules/baumaster-v2-common';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {BehaviorSubject, combineLatest, Observable, of, Subscription, throwError} from 'rxjs';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {CraftDataService} from 'src/app/services/data/craft-data.service';
import {ProtocolEntryTypeDataService} from 'src/app/services/data/protocol-entry-type-data.service';
import {ProtocolEntryLocationDataService} from 'src/app/services/data/protocol-entry-location-data.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {AlertController, IonInput, Platform, PopoverController} from '@ionic/angular';
import {IonicSelectableComponent} from 'ionic-selectable';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {catchError, distinctUntilChanged, map, startWith, switchMap, take} from 'rxjs/operators';
import _ from 'lodash';
import {CompanyCraftDataService} from '../../../services/data/company-craft-data.service';
import {ProtocolEntryPriorityPopoverComponent} from '../protocol-entry-priority-popover/protocol-entry-priority-popover.component';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {ProjectProtocolLocationDataService} from '../../../services/data/project-protocol-location-data.service';
import {ProjectProtocolEntryTypeDataService} from '../../../services/data/project-protocol-entry-type-data.service';
import {ProjectProfileDataService} from '../../../services/data/project-profile-data.service';
import {ProjectCraftDataService} from '../../../services/data/project-craft-data.service';
import {LoggingService} from '../../../services/common/logging.service';
import {TranslateService} from '@ngx-translate/core';
import {haveObjectsEqualProperties} from '../../../utils/object-utils';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {convertDateTimeToISOString, convertISOStringToDate} from 'src/app/utils/date-utils';
import {ClientDataService} from '../../../services/data/client-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {costStringToNumber} from 'src/app/utils/protocol-entry-utils';
import {DEFAULT_LNG, LanguageService} from 'src/app/services/i18n/language.service';
import {combineLatestAsync, defaultIfNullish, switchMapOrDefault} from '../../../utils/async-utils';
import {CompanyService} from '../../../services/company/company.service';
import {ProtocolEntryCompanyDataService} from 'src/app/services/data/protocol-entry-company-data.service';
import {ThemeService} from '../../../services/ui/theme.service';
import {RichTextEditorComponent} from '../../ui/rich-text-editor/rich-text-editor.component';
import {CompanyWithProjectId} from 'src/app/model/project-company';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {SelectableService} from 'src/app/services/common/selectable.service';
import {Nullish} from 'src/app/model/nullish';
import {convertErrorToMessage} from '../../../shared/errors';
import {ToastService} from '../../../services/common/toast.service';
import {MbscDatepicker} from '@mobiscroll/angular-ivy';
import {MobiscrollService} from '../../../services/common/mobiscroll.service';
import {convertFormValuesFromISOStringToDate} from '../../../utils/date-utils-form';

const LOG_SOURCE = 'ProtocolEntryFormComponent';

export interface CompanyAddresses extends IdAware {
  id: IdType;
  name: string | null;
  isActive: boolean;
}

export interface FormDirty {
  dirty: boolean;
  dirtyFormFields?: Array<string>;
}

interface CompanyWithRemoved extends Company {
  isRemoved: boolean;
}


@Component({
  selector: 'app-protocol-entry-form',
  templateUrl: './protocol-entry-form.component.html',
  styleUrls: ['./protocol-entry-form.component.scss'],
})
export class ProtocolEntryFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  static NEXT_INSTANCE_NUMBER = 0;
  @Input() protocolId: IdType;
  @Input() expressView = false;
  @Input() closeForm = false;
  @Input() isCarriedEntry = false;
  @Input() protocolEntryId: IdType | null = null;
  @Input() formDirty: FormDirty;
  @Output() formDirtyChange = new EventEmitter<FormDirty>();
  @Output() additionalFieldsChange = new EventEmitter<string | null>();
  @Input() massEditView = false;
  @Input() massEditClosedView = false;
  @Input() readonly = false;
  @Input() isChild = false;
  @Input() acrossProjects = true;
  @Input() statusTemplate?: TemplateRef<any>;
  @Input() onlyActionableEntryTypes = false;
  @Input() defaultEntryType?: Nullish<ProtocolEntryType>;
  @Input() typeRequired = false;
  @Input() shouldFocusOnTitleInput = true;

  @Output() formChange = new EventEmitter<UntypedFormGroup>();

  @Output() formDataChange = new EventEmitter<object>();

  @ViewChild('titleInput', {static: false}) titleInput: IonInput;

  private protocolEntryIdSubject = new BehaviorSubject<IdType | null>(null);
  public isCompanyInProject$: Observable<Record<IdType, ProjectCompany>>;
  public companyData: Observable<Array<CompanyWithRemoved>>;
  public companyDataAll: Observable<Array<Company>>;
  public observerCompanyData: Observable<Array<CompanyWithRemoved>>;
  public observerCompanyDataAll: Observable<Array<Company>>;
  public protocolEntryTypeData: Observable<Array<ProtocolEntryType>>;
  public disabledProtocolEntryTypeData: Observable<Array<ProtocolEntryType>>;
  public protocolEntryLocationData: Observable<Array<ProtocolEntryLocation>>;
  public disabledProtocolEntryLocationData: Observable<Array<ProtocolEntryLocation>>;
  public protocolEntryTypeDataAll: Observable<Array<ProtocolEntryType>>;
  public protocolEntryLocationDataAll: Observable<Array<ProtocolEntryLocation>>;
  public craftData: Observable<Array<Craft>>;
  public craftDataAll: Observable<Array<Craft>>;
  public disabledCraftData: Observable<Array<Craft>>;
  public companyAddresses: Array<CompanyAddresses>|undefined;
  public companyAddressesAll: Array<CompanyAddresses>|undefined;
  public disabledCompanyAddresses: Array<CompanyAddresses>|undefined;
  public disabledCompanies: Array<Company>;
  public protocolEntry: ProtocolEntry | undefined | null;
  public selectedPriorityId: number | null = null;
  public protocol: Observable<Protocol | null>;
  public priorityLevel = ProtocolEntryPriorityLevel;
  public clientData: Client | undefined;

  private companyDataSubscription: Subscription | undefined;
  private disabledCompaniesSubscription: Subscription | undefined;
  private combineCompanyCraftsSubscription: Subscription | undefined;
  private combineCompanyProfilesSubscription: Subscription | undefined;
  private protocolEntryDataSubscription: Subscription | undefined;
  private protocolEntryTypeDataSubscription: Subscription | undefined;
  private craftDataSubscription: Subscription | undefined;
  private protocolEntryLocationDataSubscription: Subscription | undefined;
  private protocolLayoutSubscription: Subscription | undefined;
  private projectSubscription: Subscription | undefined;
  public isProtocolLayoutShort: boolean;
  private ownCompanySubscription: Subscription | undefined;
  private observerCompanyIdsSubscription: Subscription | undefined;
  private protocolEntryInitializedFormWith: ProtocolEntry | undefined | null;

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  @ViewChild('startDateDatepicker', {static : false}) startDateDatepicker: MbscDatepicker;
  @ViewChild('todoUntilDatepicker', {static : false}) todoUntilDatePicker: MbscDatepicker;

  private lang: string = DEFAULT_LNG;

  @ViewChild(RichTextEditorComponent, { static: false }) editor: RichTextEditorComponent;
  public allowCreatedProjectSettings$: Observable<boolean>;

  get isChildEntry() {
    return this.isChild || !!this.protocolEntry?.parentId;
  }

  public protocolEntryForm: UntypedFormGroup = this.formBuilder.group({
    type: [''],
    title: [''],
    company: [''],
    internalAssignmentId: [''],
    priority: [null],
    text: [''],
    startDate: [''],
    todoUntil: [''],
    reminderAt: [''],
    location: [''],
    craft: [''],
    cost: ['', [Validators.pattern(/^-?[0-9]+([,.]?[0-9]+)*([,.][0-9]{0,2})?$/), Validators.pattern(/^-?(\D*\d){0,7}([,.][0-9]{0,2})?$/)]],
    number: [''],
    createdAt: [null],
    observerCompanies: [[]],
  });
  private readonly protocolEntryAttributesInForm: Array<keyof ProtocolEntry> = ['typeId', 'title', 'companyId', 'internalAssignmentId', 'priority', 'text', 'startDate', 'todoUntil', 'reminderAt',
    'locationId', 'craftId', 'cost', 'number', 'createdAt'];
  private valueChangesSubscription: Subscription | undefined;
  private startDateValueChangesSubscription: Subscription | undefined;
  private readonly companyProjectTeam: CompanyWithRemoved = {
    id: null,
    name: this.translateService.instant('project_team'),
    clientId: null,
    changedAt: new Date(),
    isActive: true,
    isRemoved: false
  };

  protocolEntry$: Observable<ProtocolEntry|undefined>;

  project$: Observable<Project|undefined>;
  project: Project|undefined;

  taxRate$: Observable<number|undefined>;

  companyById$: Observable<Record<IdType, Company>>;

  observerCompanyIds$: Observable<IdType[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private companyDataService: CompanyDataService,
    private projectCompanyDataService: ProjectCompanyDataService,
    private craftDataService: CraftDataService,
    private companyCraftService: CompanyCraftDataService,
    private protocolEntryDataService: ProtocolEntryDataService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
    private protocolEntryLocationDataService: ProtocolEntryLocationDataService,
    private projectProtocolLocationDataService: ProjectProtocolLocationDataService,
    private projectProtocolEntryTypeDataService: ProjectProtocolEntryTypeDataService,
    private projectProfileDataService: ProjectProfileDataService,
    private projectCraftDataService: ProjectCraftDataService,
    private profileService: ProfileDataService,
    private addressDataService: AddressDataService,
    private clientDataService: ClientDataService,
    public popoverCtr: PopoverController,
    private protocolDataService: ProtocolDataService,
    private protocolService: ProtocolService,
    public translateService: TranslateService,
    private loggingService: LoggingService,
    private alertCtrl: AlertController,
    private platform: Platform,
    private systemEventService: SystemEventService,
    private projectDataService: ProjectDataService,
    private languageService: LanguageService,
    private companyService: CompanyService,
    private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
    public themeService: ThemeService,
    private featureEnabledService: FeatureEnabledService,
    private selectableService: SelectableService,
    private toastService: ToastService,
    private mobiscrollService: MobiscrollService,
  ) {}

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit');
    this.languageService.selectedLanguage.subscribe((lang) => this.lang = lang);
    this.initCompanyData();
    this.isCompanyInProject$ = this.getIsCompanyInProject$();
    this.disabledCompaniesSubscription = this.companyData.subscribe(
      (companies) => this.disabledCompanies = companies.filter((company) => (company.isActive !== undefined && !company.isActive)));
    this.protocolEntryTypeData = this.getProtocolEntryTypesObservable();
    this.disabledProtocolEntryTypeData = this.protocolEntryTypeData.pipe(map((entrytypes) => entrytypes.filter((entryType) => entryType.isActive === false)))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnInit - disabledProtocolEntryTypeData(catchError)', error)));
    this.protocolEntryLocationData = this.getProtocolEntryLocationsObservable();
    this.disabledProtocolEntryLocationData = this.protocolEntryLocationData.pipe(map((locations) => locations.filter((location) => location.isActive === false)))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnInit - disabledProtocolEntryLocationData(catchError)', error)));
    this.protocolEntryTypeDataAll = this.getProtocolEntryTypesAllObservable();
    this.protocolEntryLocationDataAll = this.getProtocolEntryLocationsAllObservable();
    this.craftData = this.getCraftDataObservable();
    this.craftDataAll = this.getCraftDataAllObservable();
    this.disabledCraftData = this.craftData.pipe(map((crafts) => crafts.filter((craft) => craft.isActive === false)))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnInit - disabledCraftData(catchError)', error)));
    this.clientDataService.data.pipe(take(1))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnInit - clientDataService(catchError)', error)))
      .subscribe(clientData => {
      this.clientData = _.head(clientData);
    });
    this.formChange.emit(this.protocolEntryForm); // the form is only crated in this component. After initialization of it, we need to emit it to the parent component, so it can access it
    this.allowCreatedProjectSettings$ = this.selectableService.allowCreatedProjectSettings$;
  }

  private getIsCompanyInProject$() {
    return this.acrossProjects
      ? this.project$.pipe(
        switchMap((project) => !project ? of({} as Record<IdType, ProjectCompany>) : this.projectCompanyDataService.getDataByCompanyId$(project.id))
      ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getIsCompanyInProject$ - acrossProjects(catchError)', error)))
      : this.projectCompanyDataService.dataByCompanyId$;
  }

  private getProtocolEntryLocationsObservable(): Observable<ProtocolEntryLocation[]> {
    if (this.acrossProjects) {
      return combineLatestAsync([this.protocolEntry$, this.project$]).pipe(
        switchMap(([entry, project]) => this.projectProtocolLocationDataService.getProjectProtocolLocationsInProjectWithDeletedSuffix(project?.id, entry ? [entry.locationId] : []))
      ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getProtocolEntryLocationsObservable - acrossProjects(catchError)', error)));
    }
    return this.protocolEntry$.pipe(
      switchMap((entry) => this.projectProtocolLocationDataService.getProjectProtocolLocationsWithDeletedSuffix(entry ? [entry.locationId] : []))
    ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getProtocolEntryLocationsObservable - currentProject(catchError)', error)));
  }

  private getProtocolEntryLocationsAllObservable(): Observable<ProtocolEntryLocation[]> {
    return this.protocolEntryLocationDataService.dataActive$;
  }

  private getProtocolEntryTypesObservable(): Observable<ProtocolEntryType[]> {
    if (this.acrossProjects) {
      return combineLatestAsync([this.protocolEntry$, this.project$]).pipe(
        switchMap(([entry, project]) => this.projectProtocolEntryTypeDataService.getProjectProtocolEntryTypesInProjectWithDeletedSuffix(project?.id, entry ? [entry.typeId] : [])),
        map((types) => this.onlyActionableEntryTypes ? types.filter((type) => type.statusFieldActive) : types)
      ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getProtocolEntryTypesObservable - acrossProjects(catchError)', error)));
    }

    return this.protocolEntry$.pipe(
      switchMap((entry) => this.projectProtocolEntryTypeDataService.getProjectProtocolEntryTypesWithDeletedSuffix(entry ? [entry.typeId] : [])),
      map((types) => this.onlyActionableEntryTypes ? types.filter((type) => type.statusFieldActive) : types)
    ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getProtocolEntryTypesObservable - currentProject(catchError)', error)));
  }

  private getProtocolEntryTypesAllObservable(): Observable<ProtocolEntryType[]> {
    return this.onlyActionableEntryTypes ? this.protocolEntryTypeDataService.dataActiveStatusFieldActive$ : this.protocolEntryTypeDataService.dataActive$;
  }

  private getCraftDataObservable(): Observable<Craft[]> {
    if (this.acrossProjects) {
      return combineLatestAsync([this.protocolEntry$, this.project$]).pipe(
        switchMap(([entry, project]) => this.projectCraftDataService.getProjectCraftsInProjectWithDeletedSuffix(project?.id, entry ? [entry.craftId] : []))
      ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getCraftDataObservable - acrossProjects(catchError)', error)));
    }

    return this.protocolEntry$.pipe(
      switchMap((entry) => this.projectCraftDataService.getProjectCraftsWithDeletedSuffix(entry ? [entry.craftId] : []))
    ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getCraftDataObservable - currentProject(catchError)', error)));
  }

  private getCraftDataAllObservable(): Observable<Craft[]> {
    return this.protocolEntry$.pipe(
      switchMap((entry) => this.craftDataService.dataWithDeletedSuffixWithIds(entry ? [entry.craftId] : []))
    ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getCraftDataAllObservable - protocolEntry$(catchError)', error)));
  }

  private initCompanyData() {
    this.companyData = combineLatestAsync([this.protocolEntry$, this.project$, this.observerCompanyIds$]).pipe(
      switchMap(([entry, project, observerCompanyIds]) => this.getProjectCompaniesObservable(entry, observerCompanyIds).pipe(
        map((companies) => [companies, entry, project] as const)
      )),
      map(([companies, protocolEntry, project]) => {
        const sortedCompanies = _.chain(companies).orderBy((company) => company.name?.toLowerCase())
          .filter((company) => company.isActive === undefined || company.isActive || (protocolEntry?.companyId && company.id === protocolEntry?.companyId))
          .map((company) => ({
            ...company,
            isRemoved: project ? !company.projectIds.includes(project.id) : false,
          } as CompanyWithRemoved))
          .orderBy((company) => company.isActive === undefined || company.isActive ? 0 : 1)
          .orderBy((company) => company.isRemoved ? 1 : 0)
          .value();
        sortedCompanies.unshift(this.companyProjectTeam);
        return sortedCompanies;
      }))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('initCompanyData - companyData(catchError)', error)));
    this.companyDataAll = this.companyDataService.dataActive$.pipe(map((companies) => [this.companyProjectTeam, ...companies]))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('initCompanyData - companyDataAll(catchError)', error)));
    this.observerCompanyData = this.companyData.pipe(map((values) => values.filter((value) => value.id !== null)));
    this.observerCompanyDataAll = this.companyDataAll.pipe(map((values) => values.filter((value) => value.id !== null)));
  }

  private getProjectCompaniesObservable(protocolEntry: ProtocolEntry, observerCompanyIds: IdType[]): Observable<CompanyWithProjectId[]> {
    if (this.acrossProjects) {
      return this.project$.pipe(switchMap((project) => project ? this.projectCompanyDataService.getProjectCompanyByProjectIds([
        project.id
      ], protocolEntry?.companyId ? [protocolEntry.companyId, ...observerCompanyIds] : [...observerCompanyIds]) :
        this.projectCompanyDataService.getProjectCompaniesWithProjectId(protocolEntry?.companyId ? [protocolEntry.companyId, ...observerCompanyIds] : [...observerCompanyIds])))
        .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getProjectCompaniesObservable - acrossProjects(catchError)', error)));
    }

    return this.projectCompanyDataService.getProjectCompaniesWithProjectId(protocolEntry?.companyId ? [protocolEntry.companyId, ...observerCompanyIds] : [...observerCompanyIds])
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getProjectCompaniesObservable - currentProject(catchError)', error)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loggingService.debug(LOG_SOURCE, `ngOnChanges - [${Object.keys(changes)}]`);
    try {
      if (changes.acrossProjects && changes.acrossProjects.firstChange) {
        // input "acrossProjects" is not supposed to change and is therefore not supported to be changed
        // Code that is in this block can be considered like code in ngOnInit only that it is required earlier by other code in this ngOnChanges method
        this.protocolEntry$ = this.protocolEntryIdSubject.pipe(
          switchMapOrDefault((id) => this.acrossProjects
            ? this.protocolEntryDataService.getByIdAcrossProjects(id)
            : this.protocolEntryDataService.getById(id)
          )
        ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnChanges - protocolEntry$(catchError)', error)));

        this.protocolEntryDataSubscription = this.protocolEntry$.subscribe(protocolEntry => {
          try {
            this.initWithProtocolEntry(protocolEntry);
          } catch (error) {
            this.loggingService.error(LOG_SOURCE, `ngOnChanges - protocolEntryDataSubscription. ${convertErrorToMessage(error)}`);
            this.systemEventService.logErrorEvent(LOG_SOURCE + ' ngOnChanges - protocolEntryDataSubscription.', error);
            this.toastService.error(`ngOnChanges - protocolEntryDataSubscription. ${convertErrorToMessage(error)}`);
          }
        });

        this.project$ = this.acrossProjects
          ? this.protocolEntryIdSubject.pipe(
            switchMapOrDefault((id) => this.protocolService.getProjectByEntryId(id)),
            switchMap((project) => !project ? this.projectDataService.currentProjectObservable : of(project)),
          ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnChanges - project$ - acrossProjects(catchError)', error)))
          : this.projectDataService.currentProjectObservable;

        this.projectUnsubscribe();
        this.projectSubscription = this.project$.subscribe((project) => this.project = project);

        this.taxRate$ = this.project$.pipe(
          map((project) => project?.taxRate)
        );
        this.companyById$ = (this.acrossProjects ? this.companyDataService.dataAcrossClientsGroupedById : this.companyDataService.dataGroupedById).pipe(
          defaultIfNullish({} as Record<IdType, Company>),
          startWith({} as Record<IdType, Company>)
        );
        this.observerCompanyIds$ = this.protocolEntryIdSubject.pipe(
          distinctUntilChanged(),
          switchMapOrDefault((id) => this.acrossProjects
            ? this.protocolEntryCompanyDataService.findAllByProtocolEntryIdAcrossProjects(id)
            : this.protocolEntryCompanyDataService.findAllByProtocolEntryId(id)
          , []),
          map((protocolEntryCompanies) => protocolEntryCompanies.map(({ companyId }) => companyId)),
          distinctUntilChanged(_.isEqual)
        ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnChanges - observerCompanyIds$(catchError)', error)));
      }
      if (changes.formDirty) {
        this.loggingService.debug(LOG_SOURCE, `ngOnChanges - formDirty changed to ${changes.formDirty.currentValue}.`);
      }
      if (changes.onlyActionableEntryTypes) {
        this.protocolEntryTypeData = this.getProtocolEntryTypesObservable();
        this.protocolEntryTypeDataAll = this.getProtocolEntryTypesAllObservable();
      }
      if (changes.typeRequired) {
        if (this.typeRequired) {
          this.protocolEntryForm.get('type').addValidators(Validators.required);
        } else {
          this.protocolEntryForm.get('type').removeValidators(Validators.required);
        }
        this.protocolEntryForm.get('type').updateValueAndValidity();
      }
      if (changes.protocolId) {
        this.protocol = this.acrossProjects
          ? this.protocolDataService.getByIdAcrossProjects(this.protocolId)
          : this.protocolDataService.getById(this.protocolId);
        this.protocolLayoutUnsubscribe();
        this.protocolLayoutSubscription = this.protocolService.getIsProtocolLayoutShort$(this.protocolId, this.acrossProjects)
          .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('ngOnChanges - getIsProtocolLayoutShort$(catchError)', error)))
          .subscribe(isProtocolLayoutShort => {
            try {
              this.isProtocolLayoutShort = !!isProtocolLayoutShort;
              if (this.isProtocolLayoutShort) {
                this.ownCompanyUnsubscribe();
                this.ownCompanySubscription = this.companyService.getOwnCompany$().pipe(
                  switchMap((company) => this.projectCompanyDataService.dataByCompanyId$.pipe(
                    map((projectCompanies) => ({
                      ...company,
                      isRemoved: !projectCompanies[company.id]
                    }))
                  ))
                ).subscribe(company => {
                  try {
                    this.setCompany(company);
                  } catch (error) {
                    this.loggingService.error(LOG_SOURCE, `ngOnChanges - protocolLayoutSubscription - ownCompanySubscription. ${convertErrorToMessage(error)}`);
                    this.systemEventService.logErrorEvent(LOG_SOURCE + ' ngOnChanges - protocolLayoutSubscription - ownCompanySubscription.', error);
                    this.toastService.error(`ngOnChanges - protocolLayoutSubscription - ownCompanySubscription. ${convertErrorToMessage(error)}`);
                  }
                });
              }
            } catch (error) {
              this.loggingService.error(LOG_SOURCE, `ngOnChanges - protocolLayoutSubscription. ${convertErrorToMessage(error)}`);
              this.systemEventService.logErrorEvent(LOG_SOURCE + ' ngOnChanges - protocolLayoutSubscription.', error);
              this.toastService.error(`ngOnChanges - protocolLayoutSubscription. ${convertErrorToMessage(error)}`);
            }
        });
      }
      if (changes.protocolEntryId) {
        this.loggingService.debug(LOG_SOURCE, `ngOnChanges - protocolEntryId=${this.protocolEntryId}`);
        const protocolEntryId: IdType|null = changes.protocolEntryId.currentValue;
        if (protocolEntryId !== this.protocolEntryIdSubject.value) {
          this.protocolEntryIdSubject.next(protocolEntryId);
        }
        if (this.editor) {
          this.fixTinymceFocusQuirk();
          window.blur();
          // If "resetContent" is not done, tinymce would fire the change trigger after switching protocol entries and setting the focus on the editor field.
          this.editor?.resetContent(this.protocolEntryForm.controls.text.value ?? '');
        }
      }
      if (changes.form) {
        this.updateForm();
      }
      if (changes.shouldFocusOnTitleInput && !changes.shouldFocusOnTitleInput.firstChange &&
        changes.shouldFocusOnTitleInput.currentValue === true && changes.shouldFocusOnTitleInput.previousValue === false) {
        this.focusOnTitleInputInCreateModeWithDelay();
      }
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - ngOnChanges', error?.userMessage + '-' + error?.message);
    }
  }

  private async focusOnTitleInputInCreateModeWithDelay() {
    if (this.protocolEntryId === null) {
      setTimeout(async () => {
        await this.focusOnTitleInput();
      }, 500);
    }
  }

  private fixTinymceFocusQuirk() {
    // If no selection is present in the window, tinymce creates selection in the beginning of an input.
    // That leads to autofocus on the input, and therefore a keyboard is present on mobile devices.
    // Here we create dummy selection on body element, so tinymce does not create a (faulty) default selection.
    const selection = window.getSelection ? window.getSelection() : null;
    if (selection) {
      selection.removeAllRanges();
      const range = document.createRange();
      const body = document.body;
      range.setStart(body, 0);
      range.setEnd(body, 0);
      selection.addRange(range);
    }
    if (this.editor?.editorComponent?.editor) {
      // Moreover, we change selection range via listening to the SetSelectionRange event, so we are certain,
      // that even if setRange will be called from tinymce, we will have our dummy selection used.
      const callback = (event) => {
        event.range.setStart(document.body, 0);
        event.range.setEnd(document.body, 0);
      };
      const editor = this.editor.editorComponent.editor;
      editor.once('SetSelectionRange', callback);
      // In case SetSelectionRange is not emitted, we need to release callback
      setTimeout(() => editor.off('SetSelectionRange', callback), 100);
    }
  }

  ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy');
    this.startDateValueChangesSubscription?.unsubscribe();
    this.unsubscribeCompanyPeopleAndCompanyCraftSubscription();
    this.unsubscribeProtocolEntryData();
    this.companyDataUnsubscribe();
    this.disabledCompaniesUnsubscribe();
    this.protocolEntryTypeDataUnsubscribe();
    this.craftDataUnsubscribe();
    this.protocolEntryLocationDataUnsubscribe();
    this.ownCompanyUnsubscribe();
    this.protocolLayoutUnsubscribe();
    this.valueChangesUnsubscribe();
    this.observerCompanyIdsSubscription?.unsubscribe();
    this.projectUnsubscribe();
  }

  ngAfterViewInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngAfterViewInit');
    if (this.shouldFocusOnTitleInput) {
      this.focusOnTitleInputInCreateModeWithDelay();
    }
    this.checkFormIsDirty();
  }

  async focusOnTitleInput() {
    await this.titleInput?.setFocus();
  }

  private unsubscribeCompanyPeopleAndCompanyCraftSubscription() {
    this.combineCompanyCraftsUnsubscribe();
    this.combineCompanyProfilesUnsubscribe();
  }

  combineCompanyCraftsUnsubscribe() {
    if (this.combineCompanyCraftsSubscription) {
      this.combineCompanyCraftsSubscription.unsubscribe();
      this.combineCompanyCraftsSubscription = undefined;
    }
  }

  ownCompanyUnsubscribe() {
    if (this.ownCompanySubscription) {
      this.ownCompanySubscription.unsubscribe();
      this.ownCompanySubscription = undefined;
    }
  }

  protocolLayoutUnsubscribe() {
    if (this.protocolLayoutSubscription) {
      this.protocolLayoutSubscription.unsubscribe();
      this.protocolLayoutSubscription = undefined;
    }
  }

  valueChangesUnsubscribe() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
      this.valueChangesSubscription = undefined;
    }
  }

  combineCompanyProfilesUnsubscribe() {
    if (this.combineCompanyProfilesSubscription) {
      this.combineCompanyProfilesSubscription.unsubscribe();
      this.combineCompanyProfilesSubscription = undefined;
    }
  }

  private projectUnsubscribe() {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
      this.projectSubscription = undefined;
    }
  }

  changePriority(priorityId) {
    this.selectedPriorityId = priorityId;
    this.protocolEntryForm.get('priority').setValue(priorityId);
  }

  companyChange(event: { component: IonicSelectableComponent, value: Company & CompanyWithRemoved, values: Array<Company & CompanyWithRemoved> }) {
    const company = event.value;
    this.unsubscribeCompanyPeopleAndCompanyCraftSubscription();
    this.initCompanyPeople(company?.id);
    this.initCompanyCraft(company?.id);
  }

  initCompanyCraft(companyId: IdType) {
    try {
      this.protocolEntryForm.get('craft').reset();
      const companyCraftsData = this.getCompanyCraftsObservable(companyId);
      this.combineCompanyCraftsUnsubscribe();
      this.combineCompanyCraftsSubscription = combineLatest([this.craftData, companyCraftsData])
        .subscribe(async ([crafts, companyCrafts]) => {
          try {
            const newCompanyCrafts: Array<Craft> = [];
            companyCrafts.forEach((companyCraft) => {
              _.each(crafts, (craft) => {
                if (craft.id === companyCraft?.craftId) {
                  newCompanyCrafts.push(craft);
                  return false;
                }
              });
            });
            if (newCompanyCrafts.length === 1) {
              this.protocolEntryForm.get('craft').setValue(_.head(newCompanyCrafts));
            }
          } catch (error) {
            this.loggingService.error(LOG_SOURCE, `initCompanyCraft - combineCompanyCraftsSubscription. ${convertErrorToMessage(error)}`);
            this.systemEventService.logErrorEvent(LOG_SOURCE + ' initCompanyCraft - combineCompanyCraftsSubscription.', error);
            this.toastService.error(`initCompanyCraft - combineCompanyCraftsSubscription. ${convertErrorToMessage(error)}`);
          }
        });
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - initCompanyCraft', error?.userMessage + '-' + error?.message);
    }
  }

  private getCompanyCraftsObservable(companyId: string): Observable<CompanyCraft[]> {
    return (this.acrossProjects
      ? this.companyCraftService.dataAcrossClients$
      : this.companyCraftService.data
    ).pipe(
      map(crafts => crafts.filter(craft => craft.companyId === companyId))
    ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getCompanyCraftsObservable (catchError)', error)));
  }

  initCompanyPeople(companyId: IdType) {
    const companyProfiles: Observable<Array<Profile>> = this.getCompanyProfilesObservable(companyId);
    const companyProfilesAll: Observable<Array<Profile>> = this.getCompanyProfilesObservable(companyId, false);
    this.combineCompanyProfilesUnsubscribe();
    this.combineCompanyProfilesSubscription = combineLatestAsync([
      companyProfiles,
      companyProfilesAll,
      this.acrossProjects ? this.addressDataService.dataAcrossClients$ : this.addressDataService.data,
      this.acrossProjects ? this.project$.pipe(switchMap((project) => this.projectProfileDataService.dataByProjectId$.pipe(
        map((dataByProjectId) => dataByProjectId.get(project.id))
      ))) : this.projectProfileDataService.data,
      this.protocolEntry$
    ])
      .subscribe(async ([profiles, profilesAll, addresses, projectProfiles, protocolEntry]) => {
        try {
          const newAddresses = this.convertTopCompanyAddresses(profiles, addresses, projectProfiles, protocolEntry?.internalAssignmentId);
          const allAddresses = this.convertTopCompanyAddresses(profilesAll, addresses, projectProfiles, protocolEntry?.internalAssignmentId);
          this.protocolEntryForm.get('internalAssignmentId').reset();
          this.companyAddresses = newAddresses;
          this.companyAddressesAll = allAddresses;
          this.disabledCompanyAddresses = newAddresses.filter(address => address.isActive === false);
          this.setAssignment();
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `initCompanyPeople - combineCompanyProfilesSubscription. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' initCompanyPeople - combineCompanyProfilesSubscription.', error);
          this.toastService.error(`initCompanyPeople - combineCompanyProfilesSubscription. ${convertErrorToMessage(error)}`);
        }
      });
  }

  private getCompanyProfilesObservable(companyId: string, assignedToProject = true): Observable<Profile[]> {
    if (this.isProtocolLayoutShort) {
      return (this.acrossProjects
          ? this.profileService.dataAcrossClients$
          : this.profileService.data
      ).pipe(map(profiles => profiles.filter(profile => profile.companyId === companyId)))
        .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getCompanyProfilesObservable - isProtocolLayoutShort(catchError)', error)));
    }

    return (this.acrossProjects
      ? combineLatestAsync([this.protocolEntry$, this.protocol]).pipe(
        switchMap(([protocolEntry, protocol]) =>
          assignedToProject ? this.projectProfileDataService.getProjectProfilesForProjects(protocol?.projectId, [protocolEntry?.internalAssignmentId]) : this.profileService.data)
      )
      : this.protocolEntry$.pipe(switchMap((protocolEntry) => assignedToProject ? this.projectProfileDataService.getProjectProfiles([protocolEntry?.internalAssignmentId]) : this.profileService.data))
    ).pipe(
      map(profiles => profiles.filter(profile => profile.companyId === companyId))
    ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('getCompanyProfilesObservable(catchError)', error)));
  }

  private convertTopCompanyAddresses(profiles: Array<Profile>, addresses: Array<Address>, projectProfiles: Array<ProjectProfile>, internalAssignmentId: IdType|undefined): Array<CompanyAddresses> {
    const result = new Array<CompanyAddresses>();
    profiles.forEach((profile) => {
      _.each(addresses, (address) => {
        if (profile?.addressId === address.id && (profile.isActive === undefined || profile.isActive || (!profile.isActive && profile.id === internalAssignmentId))) {
          const isActiveInProject = projectProfiles.some(({profileId}) => profileId === profile.id);
          const isActiveProfile = (profile.isActive === undefined || profile.isActive);
          let suffix = '';
          if (!isActiveProfile) {
            suffix = ` ${this.translateService.instant('deleted')}`;
          }

          const isActive = isActiveProfile && isActiveInProject;
          result.push({
            id: profile.id,
            name: `${address.firstName} ${address.lastName}${suffix}`,
            isActive
          });
          return false;
        }
      });
    });
    return result;
  }

  private getDirtyFormFields(): Array<string> {
    return Object.entries(this.protocolEntryForm.controls).filter(([key, value]) => value.dirty).map(([key, value]) => key);
  }

  checkFormIsDirty() {
    this.valueChangesUnsubscribe();
    this.valueChangesSubscription = this.protocolEntryForm.valueChanges
      .pipe(
        catchError((error, caught) => this.observableCatchRethrowErrorHandler('checkFormIsDirty - valueChangesSubscription(catchError)', error)),
        distinctUntilChanged((a, b) => {
          return _.isEqualWith(a, b, (value, other) => {
            // We treat null and undefined as equal
            if ((value === undefined || value === null) && (other === undefined || other === null)) {
              return true;
            }

            // We treat iso string and date as equal, if they represent the same date and time
            if ((_.isDate(value) || _.isDate(other))) {
              const vDate = convertDateTimeToISOString(value);
              const oDate = convertDateTimeToISOString(other);
              return Boolean(vDate && oDate && vDate === oDate);
            }

            return undefined;
          });
        })
      )
      .subscribe((formValues) => {
        try {
          if (!this.isProtocolLayoutShort && (formValues.title?.includes('\n') || formValues.title?.includes('\r'))) {
            formValues.title = formValues.title.replace(/[\n\r]/g, '');
            this.protocolEntryForm.patchValue({
              title: formValues.title,
            }, {
              emitEvent: false,
            });
          }
          this.updateFormDirty(this.protocolEntryForm.dirty);
          this.updateForm();
          this.formDataChange.emit(formValues);
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `checkFormIsDirty - valueChangesSubscription - subscribe. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' checkFormIsDirty - valueChangesSubscription - subscribe.', error);
          this.toastService.error(`checkFormIsDirty - valueChangesSubscription - subscribe. ${convertErrorToMessage(error)}`);
        }
    });
  }

  updateFormDirty(dirty: boolean) {
    this.loggingService.debug(LOG_SOURCE, `updateFormDirty(${dirty}) called.`);
    const dirtyFormFields: Array<string> = dirty ? this.getDirtyFormFields() : [];
    const formDirty = {dirty, dirtyFormFields} as FormDirty;
    this.formDirty = formDirty;
    this.formDirtyChange.emit(formDirty);
  }

  updateForm() {
    this.formChange.emit(this.protocolEntryForm);
  }

  updateCostField(value: string) {
    const maybeNumber = costStringToNumber(value);
    if (maybeNumber === undefined || maybeNumber === null || isNaN(maybeNumber)) {
      return;
    }

    const costString = getFormattedNumber(this.lang, maybeNumber);

    this.protocolEntryForm.patchValue({
      cost: costString
    }, {
      // Changing the string format does not alter the number value itself
      // so there is no need to emit an update event
      emitEvent: false,
    });
  }

  private patchOnlyDifferentValues(object: any, form: UntypedFormGroup): boolean {
    let changed = false;
    for (const property of Object.keys(object)) {
      let objectValue = object[property];
      const formField = form.get(property);
      if (!formField) {
        continue; // there is no matching form field
      }
      const formValue = formField.value;
      if (formValue && formValue instanceof Date && objectValue && !(objectValue instanceof Date)) {
        objectValue = convertISOStringToDate(objectValue);
      }
      const isCostField = property === 'cost';
      if (!this.isEqual(objectValue, formValue, isCostField)) {
        if (formField.dirty) {
          this.loggingService.warn(LOG_SOURCE, `patchOnlyDifferentValues - field "${property}" - ignoring change since it is dirty`);
        } else {
          this.loggingService.info(LOG_SOURCE, `patchOnlyDifferentValues - field "${property}" changed from "${formValue}" to "${objectValue}".`);
          formField.setValue(objectValue, {onlySelf: true});
          changed = true;
        }
      }
    }
    return changed;
  }

  private unsubscribeProtocolEntrySubscriptions() {
    this.companyDataUnsubscribe();
    this.unsubscribeCompanyPeopleAndCompanyCraftSubscription();
    this.craftDataUnsubscribe();
    this.protocolEntryLocationDataUnsubscribe();
    this.observerCompanyIdsSubscription?.unsubscribe();
  }

  private isEqual(value1: Nullish<string> | Nullish<number> | Nullish<Date>, value2: Nullish<string> | Nullish<number> | Nullish<Date>, isCostField: boolean): boolean {
    if ((value1 === undefined || value1 === null) && (value2 === undefined || value2 === null)) {
      return true; // if one value is undefined and the other one is null, we consider it as eqal.
    }
    let value1ToCompare = value1;
    let value2ToCompare = value2;
    if (typeof value1 === 'number' && typeof value2 === 'string') {
      value2ToCompare = costStringToNumber(value2);
    } else if (typeof value1 === 'string' && typeof value2 === 'number') {
      value1ToCompare = costStringToNumber(value1);
    } else if (isCostField) {
      if ((typeof value1 !== 'string' && typeof value1 !== 'number') || (typeof value2 !== 'string' && typeof value2 !== 'number')) {
        throw new Error(`Cannot compare isCostField because of invalid type. value1 is of type "${typeof value1}" and value2 is of type "${typeof value2}".`);
      }
      value1ToCompare = costStringToNumber(value1);
      value2ToCompare = costStringToNumber(value2);
    }
    return _.isEqual(value1ToCompare, value2ToCompare);
  }

  initWithProtocolEntry(protocolEntry: ProtocolEntry | null) {
    try {
      this.loggingService.debug(LOG_SOURCE, `initWithProtocolEntry called - protocolEntry is "${protocolEntry?.title}", (${protocolEntry?.id}), protocolId = ${this.protocolId}`);
      const oldProtocolEntry = this.protocolEntry;
      const protocolEntryChanged = (!oldProtocolEntry && protocolEntry) || (oldProtocolEntry && !protocolEntry) || oldProtocolEntry?.id !== protocolEntry?.id;
      this.protocolEntry = protocolEntry;
      if (protocolEntryChanged) {
        this.startDateValueChangesSubscription?.unsubscribe();
      }
      if (!protocolEntry) {
        this.loggingService.info(LOG_SOURCE, `initWithProtocolEntry called - protocolEntry is null or undefined. Resetting form - protocolId = ${this.protocolId}`);
        this.unsubscribeProtocolEntrySubscriptions();
        this.protocolEntryForm.reset();
        this.protocolEntryInitializedFormWith = null;
        if (this.defaultEntryType && this.typeRequired) {
          const typeControl = this.protocolEntryForm.get('type');
          typeControl.setValue(this.defaultEntryType, {onlySelf: true});
          typeControl.markAsPristine({onlySelf: true});
        }
        return;
      }
      if (this.protocolEntryInitializedFormWith && this.protocolEntryInitializedFormWith.id === protocolEntry.id) {
        if (haveObjectsEqualProperties(protocolEntry, this.protocolEntryInitializedFormWith, this.protocolEntryAttributesInForm)) {
          this.loggingService.debug(LOG_SOURCE, 'initWithProtocolEntry - protocolEntry has been updated but relevant form data is identical. Not updating the form.');
          return;
        }
      }
      let formChanged: boolean;
      if (protocolEntry) {
        const protocolEntryForPatch = {
          ...protocolEntry,
          cost: protocolEntry.cost !== undefined && protocolEntry.cost !== null ? getFormattedNumber(this.lang, parseFloat(`${protocolEntry.cost}`)) : protocolEntry.cost,
          internalAssignmentId: this.getInternalAssignmentById(protocolEntry.internalAssignmentId),
        };

        if (this.protocolEntryInitializedFormWith && this.protocolEntryInitializedFormWith.id === protocolEntry.id) {
          formChanged = this.patchOnlyDifferentValues(protocolEntryForPatch, this.protocolEntryForm);
        } else {
          this.protocolEntryForm.patchValue(protocolEntryForPatch);
          formChanged = true;
        }
      } else {
        this.protocolEntryForm.patchValue(protocolEntry);
        formChanged = true;
      }
      convertFormValuesFromISOStringToDate(this.protocolEntryForm, 'startDate', 'todoUntil', 'reminderAt');
      this.protocolEntryInitializedFormWith = _.cloneDeep(protocolEntry);
      if (formChanged) {
        this.protocolEntryForm.markAsPristine();
        this.updateFormDirty(false);
      }

      if (!this.isProtocolLayoutShort) {
        if (protocolEntry.allCompanies) {
          this.setCompany(this.companyProjectTeam);
        } else {
          this.companyDataUnsubscribe();
          this.companyDataSubscription = (this.acrossProjects
            ? this.companyDataService.getByIdAcrossClients(protocolEntry.companyId).pipe(
              switchMap((company) => this.project$.pipe(
                switchMap((project) => this.projectCompanyDataService.getDataByCompanyId$(project.id)),
                map((projectCompanies) => company ? ({
                  ...company,
                  isRemoved: !projectCompanies[company.id]
                }) : undefined)
              ))
            )
            : this.companyDataService.getById(protocolEntry.companyId).pipe(
              switchMap((company) => this.projectCompanyDataService.dataByCompanyId$.pipe(
                map((projectCompanies) => company ? ({
                  ...company,
                  isRemoved: !projectCompanies[company.id]
                }) : undefined)
              ))
            )
          ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('initWithProtocolEntry - companyDataSubscription(catchError)', error)))
            .subscribe(company => {
              try {
                this.setCompany(company);
              } catch (error) {
                this.loggingService.error(LOG_SOURCE, `initWithProtocolEntry - companyDataSubscription. ${convertErrorToMessage(error)}`);
                this.systemEventService.logErrorEvent(LOG_SOURCE + ' initWithProtocolEntry - companyDataSubscription.', error);
                this.toastService.error(`initWithProtocolEntry - companyDataSubscription. ${convertErrorToMessage(error)}`);
              }
            });
        }
      }
      this.setAssignment();

      this.protocolEntryTypeDataUnsubscribe();
      this.protocolEntryTypeDataSubscription = this.protocolEntryTypeDataService.getByIdAcrossClientsWithDeletedSuffix(protocolEntry.typeId).subscribe(type => {
        this.protocolEntryForm.get('type').setValue(type, {onlySelf: true});
      });
      this.craftDataUnsubscribe();
      this.craftDataSubscription = (this.acrossProjects
        ? this.craftDataService.getByIdAcrossProjectsWithDeletedSuffix(protocolEntry.craftId)
        : this.craftDataService.getByIdWithDeletedSuffix(protocolEntry.craftId)
      ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('initWithProtocolEntry - craftDataSubscription(catchError)', error)))
        .subscribe(craft => {
          try {
            this.protocolEntryForm.get('craft').setValue(craft, {onlySelf: true});
          } catch (error) {
            this.loggingService.error(LOG_SOURCE, `initWithProtocolEntry - craftDataSubscription. ${convertErrorToMessage(error)}`);
            this.systemEventService.logErrorEvent(LOG_SOURCE + ' initWithProtocolEntry - craftDataSubscription.', error);
            this.toastService.error(`initWithProtocolEntry - craftDataSubscription. ${convertErrorToMessage(error)}`);
          }
      });
      this.protocolEntryLocationDataUnsubscribe();
      this.protocolEntryLocationDataSubscription = this.protocolEntryLocationDataService.getByIdAcrossClientsWithDeletedSuffix(protocolEntry.locationId).subscribe(location => {
        try {
          this.protocolEntryForm.get('location').setValue(location, {onlySelf: true});
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `initWithProtocolEntry - protocolEntryLocationDataSubscription. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' initWithProtocolEntry - protocolEntryLocationDataSubscription.', error);
          this.toastService.error(`initWithProtocolEntry - protocolEntryLocationDataSubscription. ${convertErrorToMessage(error)}`);
        }
      });
      if (oldProtocolEntry?.id !== protocolEntry?.id) {
        this.initializeObserverCompanies();
      }
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - initWithProtocolEntry', error?.userMessage + '-' + error?.message);
    }
  }

  public initializedWithProtocolEntryId(protocolEntryId: IdType): boolean {
    if (!this.protocolEntryId || !this.protocolEntry || !this.protocolEntryInitializedFormWith) {
      return false;
    }
    return this.protocolEntryId === protocolEntryId && this.protocolEntry.id === protocolEntryId && this.protocolEntryInitializedFormWith.id === protocolEntryId;
  }

  private initializeObserverCompanies() {
    this.observerCompanyIdsSubscription?.unsubscribe();

    this.observerCompanyIdsSubscription = this.observerCompanyIds$.subscribe((companyIds) => {
      try {
        this.protocolEntryForm.get('observerCompanies').setValue(companyIds, {onlySelf: true});
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `initializeObserverCompanies - observerCompanyIdsSubscription. ${convertErrorToMessage(error)}`);
        this.systemEventService.logErrorEvent(LOG_SOURCE + ' initializeObserverCompanies - observerCompanyIdsSubscription.', error);
        this.toastService.error(`initializeObserverCompanies - observerCompanyIdsSubscription. ${convertErrorToMessage(error)}`);
      }
    });
  }

  async showPriorityPopOver(event) {
    this.loggingService.debug(LOG_SOURCE, 'showPriorityPopOver called');
    const popover = await this.popoverCtr.create({
      component: ProtocolEntryPriorityPopoverComponent,
      event,
      translucent: true
    });

    popover.onDidDismiss()
      .then((response: any) => {
        if (typeof response.data !== 'undefined') {
          this.changePriority(response.data?.newPriority);
        }
      });
    return await popover.present();
  }

  private setCompany(company: CompanyWithRemoved) {
    const companyControl = this.protocolEntryForm.get('company');
    if (companyControl.dirty) {
      return; // do not overwrite changed values only if company data has changed.
    }
    companyControl.setValue(company, {onlySelf: true});
    this.unsubscribeCompanyPeopleAndCompanyCraftSubscription();
    this.initCompanyPeople(company?.id);
  }

  private getInternalAssignmentById(internalAssignmentId: IdType|undefined|null): CompanyAddresses|undefined|null {
    if (internalAssignmentId === undefined) {
      return undefined;
    }
    if (internalAssignmentId === null) {
      return null;
    }
    return _.find(this.companyAddresses, {id: internalAssignmentId});
  }

  private setAssignment() {
    this.protocolEntryForm.get('internalAssignmentId').setValue(this.getInternalAssignmentById(this.protocolEntry?.internalAssignmentId), {onlySelf: true});

  }
  unsubscribeProtocolEntryData() {
    if (this.protocolEntryDataSubscription) {
      this.protocolEntryDataSubscription.unsubscribe();
      this.protocolEntryDataSubscription = undefined;
    }
  }

  private companyDataUnsubscribe() {
    if (this.companyDataSubscription) {
      this.companyDataSubscription.unsubscribe();
      this.companyDataSubscription = undefined;
    }
  }

  private disabledCompaniesUnsubscribe() {
    if (this.disabledCompaniesSubscription) {
      this.disabledCompaniesSubscription.unsubscribe();
      this.disabledCompaniesSubscription = undefined;
    }
  }

  private protocolEntryTypeDataUnsubscribe() {
    if (this.protocolEntryTypeDataSubscription) {
      this.protocolEntryTypeDataSubscription.unsubscribe();
      this.protocolEntryTypeDataSubscription = undefined;
    }
  }

  private craftDataUnsubscribe() {
    if (this.craftDataSubscription) {
      this.craftDataSubscription.unsubscribe();
      this.craftDataSubscription = undefined;
    }
  }

  private protocolEntryLocationDataUnsubscribe() {
    if (this.protocolEntryLocationDataSubscription) {
      this.protocolEntryLocationDataSubscription.unsubscribe();
      this.protocolEntryLocationDataSubscription = undefined;
    }
  }

  async notImplemented(){
    const alert = await this.alertCtrl.create({
      message: `${this.translateService.instant('underConstruction')}`,
      buttons: ['OK']
    });
    await alert.present();
  }

  onAdditionalFieldsChange(nameableDropdownId: string) {
    this.additionalFieldsChange.emit(nameableDropdownId);
  }


  closeCalender() {
    // workaround for ios, mbsc opens sometimes multiple calender dialogs
    if (this.platform.is('ios')) {
      const calenderModals = document.querySelectorAll('div.mbsc-calendar');
      for (const modal of calenderModals[Symbol.iterator]()) {
        modal.remove();
      }
    }
  }

  createNewLocationFunction = (text?: string): Promise<ProtocolEntryLocation|undefined> => {
    return this.selectableService.createNewLocation(text, this.getProjectMandatory().id);
  };

  assignLocationToProjectFunction = (item: ProtocolEntryLocation, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignLocationToProject(item, assign, this.getProjectMandatory().id);
  };

  createNewCraftFunction = (text?: string): Promise<Craft|undefined> => {
    return this.selectableService.createNewCraft(text, this.getProjectMandatory().id);
  };

  assignCraftToProjectFunction = (item: Craft, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignCraftToProject(item, assign, this.getProjectMandatory().id);
  };

  createNewCompanyFunction = (text?: string): Promise<Company|undefined> => {
    return this.selectableService.createNewCompany(text, this.getProjectMandatory().id);
  };

  assignCompanyToProjectFunction = (item: Company, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignCompanyToProject(item, assign, this.getProjectMandatory().id);
  };

  createNewProfileFunction = (text?: string): Promise<Profile|undefined> => {
    const company = this.protocolEntryForm.controls.company.value;
    if (!company) {
      throw new Error('createNewProfileFunction - no company selected.');
    }
    return this.selectableService.createNewProfile(company, text, this.getProjectMandatory().id);
  };

  assignProfileToProjectFunction = (item: CompanyAddresses, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignProfileToProject(item, assign, this.getProjectMandatory().id);
  };

  createNewProtocolEntryTypeFunction = (text?: string): Promise<ProtocolEntryType|undefined> => {
    return this.selectableService.createNewProtocolEntryType(text, this.getProjectMandatory().id);
  };

  assignProtocolEntryTypeToProjectFunction = (item: ProtocolEntryType, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignProtocolEntryTypeToProject(item, assign, this.getProjectMandatory().id);
  };

  private getProjectMandatory(): Project {
    if (!this.project) {
      throw new Error('No project set.');
    }
    return this.project;
  }

  private observableCatchRethrowErrorHandler(message: string, error: any): Observable<never> {
    this.loggingService.error(LOG_SOURCE, `${message} - ${convertErrorToMessage(error)}`);
    this.systemEventService.logErrorEvent(LOG_SOURCE + ' ' + message, error);
    this.toastService.error(`${message} - ${convertErrorToMessage(error)}`);
    return throwError(error);
  }

  resetStartDate = (event: any) => {
    this.startDateDatepicker.setVal(null)
    this.startDateDatepicker.close();
  }

  resetTodoUntil = (event: any) => {
    this.todoUntilDatePicker.setVal(null)
    this.todoUntilDatePicker.close();
  }

}
