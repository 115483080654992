import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {DashboardFilterMultiButtonItem} from 'src/app/model/dashboard-filter';
import {EntryCardModel} from 'src/app/model/entry-card-model';
import {DEFAULT_ENTRY_PRIORITY} from 'src/app/shared/constants';
import {presentWithSuffixes, activeOrPresentWithSuffixes, IdAndActiveAware} from 'src/app/utils/active-utils';
import {combineLatestAsync, defaultIfNullish} from 'src/app/utils/async-utils';
import {compareObjectsWithPrimitiveValues} from 'src/app/utils/compare-utils';
import {
  Company,
  Craft, NameableDropdown,
  Project,
  ProtocolEntryIconStatus,
  ProtocolEntryLocation,
  ProtocolEntryPriorityLevel,
  ProtocolEntryPriorityType,
  ProtocolEntryType
} from 'submodules/baumaster-v2-common';
import {ClientService} from '../client/client.service';
import {AddressDataService} from '../data/address-data.service';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {NameableDropdownItemDataService} from '../data/nameable-dropdown-item-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProjectCompanyDataService} from '../data/project-company-data.service';
import {ProjectCraftDataService} from '../data/project-craft-data.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProjectProfileDataService} from '../data/project-profile-data.service';
import {ProjectProtocolEntryTypeDataService} from '../data/project-protocol-entry-type-data.service';
import {ProjectProtocolLocationDataService} from '../data/project-protocol-location-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {EMPTY_FILTER_ID} from 'src/app/utils/filter-utils';

export type ActivableWithNameIdAware = IdAndActiveAware & {
  name: string;
};

@Injectable()
export abstract class AbstractEntryFilterByService {

  private readonly currentProjectArray$: Observable<Project[]> = this.projectDataService.currentProjectObservable.pipe(
    map((project) => project ? [project] : undefined),
    defaultIfNullish([]),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  showRecentlyUsedInCurrentProject$ = of(true);
  nameableDropdownName$ = this.clientService.ownClientNameableDropdownName$;
  companies$: Observable<(Company & {isDeletedOrNotInProject?: boolean})[]>;
  crafts$: Observable<Craft[]>;

  private allProfiles$: Observable<ActivableWithNameIdAware[]> = combineLatestAsync([
    this.profileDataService.dataForOwnClient$,
    this.addressDataService.dataForOwnClientGroupedById
  ]).pipe(
    map(([profiles, addressById]) => profiles.map((profile) => ({
      id: profile.id,
      name: `${addressById[profile.addressId]?.firstName} ${addressById[profile.addressId]?.lastName}`,
      isActive: profile.isActive,
    })))
  );

  profiles$: Observable<ActivableWithNameIdAware[]>;

  entryTypes$: Observable<ProtocolEntryType[]>;
  customFields$: Observable<NameableDropdown[]>;
  locations$: Observable<ProtocolEntryLocation[]>;

  statuses$: Observable<DashboardFilterMultiButtonItem<ProtocolEntryIconStatus>[]> = this.translateService.get([
    'protocolEntry.status.open',
    'protocolEntry.status.waiting',
    'protocolEntry.status.done'
  ]).pipe(
    distinctUntilChanged(compareObjectsWithPrimitiveValues),
    map(({
      'protocolEntry.status.open': openLabel,
      'protocolEntry.status.waiting': onHoldLabel,
      'protocolEntry.status.done': doneLabel
    }) => [
      { value: ProtocolEntryIconStatus.OPEN, label: openLabel, icon: ['fas', 'circle'], iconClasses: ['error'] },
      { value: ProtocolEntryIconStatus.ON_HOLD, label: onHoldLabel, icon: ['fas', 'circle'], iconClasses: ['warning'] },
      { value: ProtocolEntryIconStatus.DONE, label: doneLabel, icon: ['fas', 'circle'], iconClasses: ['success'] },
    ])
  );
  priorities$: Observable<DashboardFilterMultiButtonItem<ProtocolEntryPriorityType>[]> = this.translateService.get([
    'priorities.no',
    'priorities.low',
    'priorities.medium',
    'priorities.high'
  ]).pipe(
    distinctUntilChanged(compareObjectsWithPrimitiveValues),
    map(({
      'priorities.no': noLabel,
      'priorities.low': lowLabel,
      'priorities.medium': mediumLabel,
      'priorities.high': highLabel
    }) => [
      {
        value: DEFAULT_ENTRY_PRIORITY,
        label: noLabel,
        icon: ['bau', 'flag'],
        iconClasses: ['inactive']
      },
      {
        value: ProtocolEntryPriorityLevel.LOW,
        label: lowLabel,
        icon: ['bau', 'flag'],
        iconClasses: ['text-primary']
      },
      {
        value: ProtocolEntryPriorityLevel.MEDIUM,
        label: mediumLabel,
        icon: ['bau', 'flag'],
        iconClasses: ['warning']
      },
      {
        value: ProtocolEntryPriorityLevel.HIGH,
        label: highLabel,
        icon: ['bau', 'flag'],
        iconClasses: ['danger']
      }
    ])
  );

  constructor(
    entries$: Observable<EntryCardModel[]>,
    activePresentMode: 'activeOrPresent' | 'presentOnly' = 'activeOrPresent',
    private projectDataService: ProjectDataService = inject(ProjectDataService),
    private clientService: ClientService = inject(ClientService),
    private companyDataService: CompanyDataService = inject(CompanyDataService),
    private projectCompanyDataService: ProjectCompanyDataService = inject(ProjectCompanyDataService),
    private craftDataService: CraftDataService = inject(CraftDataService),
    private projectCraftDataService: ProjectCraftDataService = inject(ProjectCraftDataService),
    private profileDataService: ProfileDataService = inject(ProfileDataService),
    private projectProfileDataService: ProjectProfileDataService = inject(ProjectProfileDataService),
    private addressDataService: AddressDataService = inject(AddressDataService),
    protected translateService: TranslateService = inject(TranslateService),
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService = inject(ProtocolEntryTypeDataService),
    private projectProtocolEntryTypeDataService: ProjectProtocolEntryTypeDataService = inject(ProjectProtocolEntryTypeDataService),
    private nameableDropdownDataService: NameableDropdownDataService = inject(NameableDropdownDataService),
    private nameableDropdownItemDataService: NameableDropdownItemDataService = inject(NameableDropdownItemDataService),
    private protocolEntryLocationDataService: ProtocolEntryLocationDataService = inject(ProtocolEntryLocationDataService),
    private projectProtocolLocationDataService: ProjectProtocolLocationDataService = inject(ProjectProtocolLocationDataService),
  ) {
    const activePresentFn = activePresentMode === 'activeOrPresent' ? activeOrPresentWithSuffixes : presentWithSuffixes;
    this.companies$ = activePresentFn(
      this.companyDataService.data,
      this.projectCompanyDataService.data,
      this.currentProjectArray$,
      entries$,
      'companyId',
      'name',
      ['companyId', 'observerCompanies'],
      this.translateService
    ).pipe(
      map(companies => [{ id: EMPTY_FILTER_ID, name: this.translateService.instant('emptyFilter'), clientId: null, changedAt: new Date(), isActive: true }, ...companies])
    );
    this.crafts$ = activePresentFn(
      this.craftDataService.data,
      this.projectCraftDataService.data,
      this.currentProjectArray$,
      entries$,
      'craftId',
      'name',
      ['craftId'],
      this.translateService
    ).pipe(
      map(crafts => [{ id: EMPTY_FILTER_ID, name: this.translateService.instant('emptyFilter'), clientId: null, changedAt: new Date(), isActive: true }, ...crafts])
    );

    this.profiles$ = activePresentFn(
      this.allProfiles$,
      this.projectProfileDataService.data,
      this.currentProjectArray$,
      entries$,
      'profileId',
      'name',
      ['internalAssignmentId'],
      this.translateService
    ).pipe(
      map(profiles => [{ id: EMPTY_FILTER_ID, name: this.translateService.instant('emptyFilter'), clientId: null, changedAt: new Date(), isActive: true }, ...profiles])
    );
    this.entryTypes$ = activePresentFn(
      this.protocolEntryTypeDataService.data,
      this.projectProtocolEntryTypeDataService.data,
      this.currentProjectArray$,
      entries$,
      'protocolentrytypeId',
      'name',
      ['typeId'],
      this.translateService
    ).pipe(
      map(entryTypes => [{ id: EMPTY_FILTER_ID, name: this.translateService.instant('emptyFilter'), clientId: null, changedAt: new Date(), isActive: true, statusFieldActive: false }, ...entryTypes])
    );
    this.customFields$ = activePresentFn(
      this.nameableDropdownDataService.data,
      this.nameableDropdownItemDataService.data,
      this.currentProjectArray$,
      entries$,
      'nameabledropdownId',
      'name',
      ['nameableDropdownId'],
      this.translateService
    ).pipe(
      map(customFields => [{ id: EMPTY_FILTER_ID, name: this.translateService.instant('emptyFilter'), clientId: null, changedAt: new Date(), isActive: true }, ...customFields])
    );
    this.locations$ = activePresentFn(
      this.protocolEntryLocationDataService.data,
      this.projectProtocolLocationDataService.data,
      this.currentProjectArray$,
      entries$,
      'protocolentrylocationId',
      'location',
      ['locationId'],
      this.translateService
    ).pipe(
      map(locations => [{ id: EMPTY_FILTER_ID, location: this.translateService.instant('emptyFilter'), clientId: null, changedAt: new Date(), isActive: true }, ...locations])
    );
  }
}
