import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NavController, ViewDidEnter} from '@ionic/angular';
import {animationFrameScheduler, Observable, of, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {EntryCardListComponent} from 'src/app/components/entry/entry-card-list/entry-card-list.component';
import {EntryCardModel, TaskCardView} from 'src/app/model/entry-card-model';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {ProtocolEntrySelectionService} from 'src/app/services/protocol/protocol-entry-selection.service';
import {TasksPageService} from 'src/app/services/tasks/tasks-page.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {IdType} from 'submodules/baumaster-v2-common';
import {LoggingService} from '../../services/common/logging.service';
import {NavigateHelperService} from 'src/app/services/common/navigate-helper.service';
import {SideBarSettingsService} from 'src/app/services/sidebar/sidebar-settings.service';

const LOG_SOURCE = 'TasksCardViewPage';
const isDetailUrl = (url: string): boolean => Boolean(url?.includes('card/entry'));

@Component({
  selector: 'app-tasks-card-view',
  templateUrl: './tasks-card-view.page.html',
  styleUrls: ['./tasks-card-view.page.scss'],
  providers: [ProtocolEntrySelectionService, PageDidEnterLifecycleService],
})
export class TasksCardViewPage implements OnInit, OnDestroy, ViewDidEnter {
  @ViewChild(EntryCardListComponent, {static: false})
  entryCardListComponent: EntryCardListComponent;

  private readonly currentUrl$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event) => event.url),
    startWith(this.router.url)
  );

  readonly destroy$ = new Subject<void>();
  readonly hasTasksStatus$ = this.tasksPageService.hasEntriesStatus$;
  readonly isSplitVisible$ = this.deviceService.isAboveBreakpoint(Breakpoints.lg);
  private readonly isAboveXxl$ = this.deviceService.isAboveBreakpoint(Breakpoints.xxl);
  private readonly isAboveXl$ = this.deviceService.isAboveBreakpoint(Breakpoints.xl);
  private readonly isNotAboveXxlAndPinnedSideBar$ = combineLatestAsync([this.sideBarSettingsService.sideBarSetting$, this.isAboveXxl$]).pipe(
    map(([sideBarSetting, isAboveXxl]) => sideBarSetting.pinned && !isAboveXxl));
  readonly isFilterVisible$ = combineLatestAsync([this.isAboveXl$, this.isNotAboveXxlAndPinnedSideBar$]).pipe(
    map(([isAboveXl, isNotAboveXxlAndPinnedSideBar]) => {
      return isNotAboveXxlAndPinnedSideBar ? false : isAboveXl;
    })
  );
  private sideBarSettingSubscription: Subscription;
  private _isMenuPinned = false;

  get isMenuPinned() {
    return this._isMenuPinned;
  }

  readonly isInEntryView$ = this.currentUrl$.pipe(map((url) => url.includes('/entry')));
  readonly taskId$: Observable<string|null> = this.currentUrl$.pipe(map((url) => url.includes('/entry') ? url.substring(url.indexOf('/entry') + '/entry/'.length) : null));
  readonly showBackButton$ = combineLatestAsync([
    this.isSplitVisible$,
    this.isInEntryView$
  ]).pipe(map(([isSplitVisible, isInEntryView]) => !isSplitVisible && isInEntryView));
  readonly backButtonPage$ = this.isSplitVisible$.pipe(
    switchMap((isSplitVisible) => {
      if (isSplitVisible) {
        return of('/tasks/card');
      }

      return this.currentUrl$.pipe(
        map((url) => url.includes('/entry') ? url.substring(0, url.indexOf('/entry')) : '/tasks/card')
      );
    })
  );
  private routerTasksNavigationEnd$ = this.currentUrl$.pipe(
    filter((url) => url.startsWith('/tasks')));
  private taskCardViews$: Observable<Array<TaskCardView>> = combineLatestAsync([this.isSplitVisible$, this.routerTasksNavigationEnd$, this.isFilterVisible$])
    .pipe(map(([isSplitVisible, url, showFilter]) => {
      const taskCardViews = new Array<TaskCardView>();
      if (isSplitVisible) {
        taskCardViews.push('LIST');
        taskCardViews.push('DETAIL');
      } else {
        taskCardViews.push(isDetailUrl(url) ? 'DETAIL' : 'LIST');
      }
      if (showFilter) {
        taskCardViews.push('FILTER');
      }
      return taskCardViews;
    }));
  protected readonly currentTaskId$ = this.tasksPageService.currentTaskId$;
  taskCardViews: Array<TaskCardView> = ['LIST'];
  cardListRendered = new Subject<unknown>();

  constructor(
    private router: Router,
    private navController: NavController,
    private tasksPageService: TasksPageService,
    private deviceService: DeviceService,
    private loggingService: LoggingService,
    private protocolEntrySelectionService: ProtocolEntrySelectionService,
    private pageDidEnterLifecycleService: PageDidEnterLifecycleService,
    private cdRef: ChangeDetectorRef,
    private navigateHelperService: NavigateHelperService,
    private sideBarSettingsService: SideBarSettingsService
  ) { }

  ngOnInit() {
    this.watchEntryGone();
    this.watchTaskCardView();
    this.watchTaskSort();
    this.jumpToSelectedEntry();
    this.watchEntryChangedOnNavigation();
    this.protocolEntrySelectionService.leaveSelectModeOnAllUnselected = true;
    this.sideBarSettingSubscription = this.sideBarSettingsService.sideBarSetting$.pipe(
      map(sideBarSetting => sideBarSetting.pinned)
    ).subscribe(isPinned => this._isMenuPinned = isPinned);
  }

  ionViewDidEnter() {
    this.pageDidEnterLifecycleService.pageDidEnter();
  }

  private watchTaskSort() {
    combineLatestAsync([
      this.taskId$,
      this.tasksPageService.sortOrderAsc$
    ]).pipe(distinctUntilChanged(([prevTaskId, prevSortOrder], [currTaskId, currSortOrder]) =>
        prevSortOrder === currSortOrder
      ),
      takeUntil(this.destroy$)
    ).subscribe(([taskId, sortOrderAsc]) => {
      if (this.entryCardListComponent) {
        this.entryCardListComponent.scrollToEntry(taskId, { scheduleAtNextRender: true });
      }
    });
  }

  private jumpToSelectedEntry() {
    this.cardListRendered.pipe(
      take(1),
      tap(() => this.cdRef.detectChanges()),
      debounceTime(0, animationFrameScheduler)
    ).subscribe(() => {
      const { url } = this.router;
      if (this.entryCardListComponent && url.startsWith('/tasks') && url.includes('/entry')) {
        const id = url.slice(url.indexOf('/entry') + 7);
        this.entryCardListComponent.scrollToEntry(id);
      }
    });
  }

  private watchEntryChangedOnNavigation() {
    combineLatestAsync([
      this.taskId$,
      this.tasksPageService.entriesFiltered$
    ]).pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(([taskId, tasks]) => {
      if (this.entryCardListComponent) {
        if (tasks.some(task => task.id === taskId) && this.navigateHelperService.navigationFromTaskToTaskAcrossProject === true) {
          this.navigateHelperService.navigationFromTaskToTaskAcrossProject = false;
          this.entryCardListComponent.scrollToEntry(taskId, { scheduleAtNextRender: false });
        }
      }
    });
  }

  private watchEntryGone() {
    combineLatestAsync([
      this.taskId$,
      this.tasksPageService.entriesFiltered$,
      this.isSplitVisible$
    ]).pipe(
      filter(([entryId, tasks, isSplitVisible]) => this.router.url.startsWith('/tasks') && isSplitVisible &&
            !tasks.some((task) => task.id === entryId) && !this.navigateHelperService.currentlyNavigatingToTaskOrEntry),
      takeUntil(this.destroy$)
    ).subscribe(async ([, tasks]) => {
      if (!(await this.navigateToFirstTask(tasks))) {
        this.navController.navigateRoot(['/tasks', 'card']);
      }
    });
  }

  private watchTaskCardView() {
    this.taskCardViews$.pipe(takeUntil(this.destroy$))
      .subscribe((taskCardViews) => {
        this.taskCardViews = taskCardViews;
        this.loggingService.debug(LOG_SOURCE, `taskCardViews=${taskCardViews}`);
        if (this.taskCardViews.includes('DETAIL') && !this.taskCardViews.includes('LIST') && this.protocolEntrySelectionService.isSelectMode) {
          this.protocolEntrySelectionService.leaveSelectMode();
        }
      });
  }

  async handleTaskCreated(taskId: IdType) {
    await this.navController.navigateRoot(['/tasks', 'card', 'entry', taskId]);
    this.entryCardListComponent.scrollToEntry(taskId);
  }

  async navigateToFirstTask(tasks?: EntryCardModel[]) {
    if (!tasks) {
      tasks = await observableToPromise(this.tasksPageService.entriesFiltered$);
    }
    const [firstTask] = tasks;
    if (firstTask) {
      await this.navController.navigateBack(['/tasks', 'card', 'entry', firstTask.id]);
      return true;
    }

    return false;
  }

  async onSplitPaneVisible(event: CustomEvent<{ visible: boolean; }>) {
    if (event.detail.visible && !this.router.url.includes('/entry')) {
      await this.navigateToFirstTask();
    }
  }

  navigateToTask(entry: EntryCardModel) {
    this.navController.navigateRoot(['/tasks', 'card', 'entry', entry.id]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if(this.sideBarSettingSubscription) {
      this.sideBarSettingSubscription.unsubscribe();
    }
  }

}
